import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Logo from '../images/header-logo--light.svg'

import '../styles/styles.scss'
import Layout from '../components/Layout/Layout'

const browser = typeof window !== 'undefined' && window

const NotFoundPage: React.FunctionComponent<any> = (): JSX.Element | null => {
  if (!browser) return null

  const data = useStaticQuery(query)

  return (
    <Layout
      seo={{ title: 'Not Found' }}
      header={{ hide: true }}
      footer={{ hide: true }}
    >
      <section className="not-found">
        <Img
          className="full-screen"
          fluid={data.bgImage.childImageSharp.fluid}
        />
        <div className="not-found__content d-flex">
          <img className="not-found__logo" src={Logo} alt="Allegro" />
          <h1 className="not-found__title">404</h1>
          <p className="not-found__subtitle">Page not found!</p>
          <Link className="not-found__link" to="/">
            Back Home
          </Link>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    bgImage: file(relativePath: { eq: "bg-404@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
